import React from 'react';
import { useTranslation } from 'react-i18next';

import diary from 'assets/img/diary.png';
import { vh } from 'utils';

const Diary = () => {
  const [t] = useTranslation();
  return (
    <>
      <div style={styles.hint}>
        <h2>{t('Hint-diary-1')}</h2>
        <p>{t('Hint-diary-2')}</p>
        <h2>{t('Hint-diary-3')}</h2>
        <p>{t('Hint-diary-4')}</p>
        <p>{t('Hint-diary-5')}</p>
      </div>
      <img style={styles.img} src={diary} alt="" />
    </>
  );
};

export default Diary;
const styles = {
  hint: {
    width: '40%',
    height: vh(80),
    marginLeft: '5vw',
    paddingRight: '7vw',
    textAlign: 'justify',
    overflow: 'auto',
  },
  img: {
    width: '30%',
    height: 'auto',
    marginRight: '10vw',
  },
};
