import React from 'react';
import PropTypes from 'prop-types';
import { animated, useSpring } from 'react-spring';

import arrow from 'assets/svg/arrow.svg';
import { vh } from 'utils';

const TextOverlay = (props) => {
  const { onClose, children } = props;
  const fadeIn = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  });

  return (
    <animated.div
      style={{
        ...styles.wrapper,
        ...fadeIn,
      }}
      className="full absolute-full"
    >
      <p style={styles.text}>{children}</p>
      <span style={styles.closeBT} onClick={onClose} role="button" tabIndex={0} />
    </animated.div>
  );
};
TextOverlay.propTypes = {
  onClose: PropTypes.func,
};
export default TextOverlay;
const styles = {
  wrapper: {
    backgroundColor: '#841F28CC',
    backgroundSize: 'cover',
    backgroundPosition: 'bottom right',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    maxWidth: '66%',
    fontWieght: 'bold',
  },
  closeBT: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: vh(5),
    backgroundImage: `url(${arrow})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: vh(8),
    backgroundPosition: 'center center',
    width: vh(12),
    height: vh(12),
  },
};
