import React from 'react';
import { useTranslation } from 'react-i18next';

import bg from 'assets/img/bg-home.png';

const WarningOrientation = () => {
  const [t] = useTranslation();
  return (
    <div
      className="flex-centered col full"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'bottom right',
        zIndex: 100,
      }}
    >
      <h2
        style={{
          padding: '2rem',
        }}
      >
        {t('Veuillez tourner votre téléphone en mode paysage')}
      </h2>
    </div>
  );
};

export default WarningOrientation;
