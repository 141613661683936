export const QR_CODES = ["Accéder à l'appartement", 'Poser le diagnostic'];
export const COMPUTER_PASSWORD = 'AZERTY';
export const ROOM_HELP_DELAY = 20000;
export const ROOMS_DATA = {
  duration: 180,
  scenes: [
    {
      id: 'bedroom',
      name: 'Bedroom',
      levels: [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true,
        },
        {
          tileSize: 512,
          size: 512,
        },
        {
          tileSize: 512,
          size: 1024,
        },
      ],
      faceSize: 1024,
      initialViewParameters: {
        pitch: 0,
        yaw: 0,
        fov: 1.5707963267948966,
      },
      hints: [
        {
          yaw: 2.437871308697879,
          pitch: 0.6295554317213359,
          id: 'photo',
        },
        {
          yaw: 2.9914147635534647,
          pitch: 0.7954020788342948,
          id: 'diary',
        },
        {
          yaw: 0.30094045459549257,
          pitch: 0.5223874273709974,
          id: 'phone',
        },
        {
          yaw: -0.36221013143949676,
          pitch: 0.7430070727467157,
          preventAutoFind: true,
          id: 'computer',
        },
        {
          yaw: 1.232492750693721,
          pitch: 0.26644759823837205,
          id: 'kitchen',
          room: 'kitchen',
        },
      ],
    },
    {
      id: 'kitchen',
      name: 'Kitchen',
      levels: [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true,
        },
        {
          tileSize: 512,
          size: 512,
        },
        {
          tileSize: 512,
          size: 1024,
        },
      ],
      faceSize: 1024,
      initialViewParameters: {
        pitch: 0,
        yaw: 0,
        fov: 1.5707963267948966,
      },
      hints: [
        {
          yaw: 0.9020339200557856,
          pitch: 0.09639485904936684,
          id: 'bedroom',
          room: 'bedroom',
        },
        {
          yaw: 1.286931929112363,
          pitch: 0.20641269944802154,
          id: 'post-it',
        },
        // {
        //   yaw: -0.14917856717859124,
        //   pitch: 0.6325178478267119,
        //   id: "bottle",
        // },
        {
          yaw: -0.6939029095400784,
          pitch: 0.48064711033845064,
          id: 'bottle',
        },
        // {
        //   yaw: -0.5427176264750599,
        //   pitch: 0.6022763866118073,
        //   id: "bottle",
        // },
        {
          yaw: -1.9805786837600934,
          pitch: 0.5359077220065913,
          id: 'pills',
        },
      ],
    },
  ],
};
export const DIAGNOSTIC = [
  {
    options: [
      {
        solution: 1,
        correct: true,
      },
      {
        solution: 2,
      },
      {
        solution: 2,
      },
      {
        solution: 2,
      },
    ],
  },
  {
    options: [
      {
        solution: 1,
        correct: true,
      },
      {
        solution: 2,
        correct: true,
      },
      {
        solution: 3,
      },
      {
        solution: 4,
        correct: true,
      },
    ],
  },
  {
    options: [
      {
        solution: 1,
        correct: true,
      },
      {
        solution: 2,
        correct: true,
      },
      {
        solution: 3,
      },
      {
        solution: 4,
      },
    ],
  },
  {
    options: [
      {
        solution: 1,
        correct: true,
      },
      {
        solution: 1,
        correct: true,
      },
      {
        solution: 2,
      },
      {
        solution: 3,
      },
    ],
  },
  {
    image: 'Schistocyte',
  },
  {
    options: [
      {
        solution: 1,
        correct: true,
      },
      {
        solution: 1,
        correct: true,
      },
      {
        solution: 1,
        correct: true,
      },
      {
        solution: 2,
      },
    ],
  },
  {
    options: [
      {
        solution: 1,
      },
      {
        solution: 2,
        correct: true,
      },
      {
        solution: 3,
      },
      {
        solution: 4,
      },
    ],
  },
  {
    options: [
      {
        solution: 1,
        correct: true,
      },
      {
        solution: 2,
        correct: true,
      },
      {
        solution: 3,
      },
      {
        solution: 4,
      },
    ],
  },
  {
    text: 'PTTA-final',
  },
  {
    text: 'PTTA-final-2',
  },
];
