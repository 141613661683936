import React, { useState, useCallback, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { animated, useSpring } from 'react-spring';
import QrReader from 'react-qr-reader';

import { HemergencyContext, NEXT_STEP } from 'context/hemergency-context';
import { QR_CODES } from 'Constants';

import nurse from 'assets/svg/nurse.svg';
import logo from 'assets/svg/logo.svg';
import { vh } from 'utils';
import Button from 'components/Button';

const SKIP_DELAY = 60000;

const Scan = (props) => {
  const { data } = props;
  const { qrIndex, scanOnly } = data;
  const { dispatch } = useContext(HemergencyContext);
  const [t] = useTranslation();
  const [isScanning, setIsScanning] = useState(!!scanOnly);

  const fadeIn = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  });
  const startScanning = useCallback(() => {
    setIsScanning(true);
  }, [setIsScanning]);

  const handleScan = useCallback(
    (scanData) => {
      const id = QR_CODES.indexOf(scanData);
      if (!scanData || id !== qrIndex) {
        return;
      }
      setIsScanning(false);
      dispatch({
        type: NEXT_STEP,
      });
    },
    [qrIndex, setIsScanning, dispatch]
  );
  const handleError = useCallback(
    (err) => {
      console.log(err);
      setIsScanning(false);
      dispatch({
        type: NEXT_STEP,
      });
    },
    [setIsScanning, dispatch]
  );

  useEffect(() => {
    if (isScanning) {
      const skip = () => {
        dispatch({
          type: NEXT_STEP,
        });
      };
      const timeout = setTimeout(skip, SKIP_DELAY);
      if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_ALLOW_SKIP_QR === 'true') {
        document.body.addEventListener('click', skip);
      }
      return () => {
        clearTimeout(timeout);
        if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_ALLOW_SKIP_QR === 'true') {
          document.body.removeEventListener('click', skip);
        }
      };
    }
    return undefined;
  }, [isScanning, dispatch]);

  return (
    <>
      <img
        src={nurse}
        alt="Hemergency"
        style={{
          ...styles.nurse,
        }}
      />
      {!isScanning ? (
        <div>
          <animated.img
            className="absolute-centered-h"
            src={logo}
            style={{
              ...styles.logo,
              ...fadeIn,
            }}
            alt="Hermergency"
          />
          <p className="absolute-centered-h" style={styles.text}>
            {t('Scan-instruction')}
          </p>
          <Button style={styles.bt} onClick={startScanning}>
            {t('Scanner')}
          </Button>
        </div>
      ) : (
        <QrReader
          delay={300}
          onError={handleError}
          onScan={handleScan}
          className="qr-reader absolute-centered full"
        />
      )}
    </>
  );
};
Scan.propTypes = {
  data: PropTypes.any,
};
export default Scan;
const styles = {
  nurse: {
    height: vh(15),
    position: 'absolute',
    top: '5vw',
    left: '5vw',
  },
  logo: {
    top: '5vw',
    width: vh(35),
    height: 'auto',
    pointerEvents: 'none',
  },
  text: {
    top: vh(45),
    width: '80%',
    fontSize: vh(4.5),
  },
  bt: {
    bottom: vh(10),
  },
};
