import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useSound from 'use-sound';

import wave from 'assets/img/voice.png';
import { vh } from 'utils';

const AudioWave = (props) => {
  const { audio, onFinished, hide } = props;
  const waveDiv = useRef();
  const [wavePos, setWavePos] = useState(0);
  const [playAudio, { isPlaying, duration, stop }] = useSound(audio, {
    interrupt: true,
  });

  useEffect(() => {
    if (!audio) {
      stop();
      return undefined;
    }
    playAudio();
    return () => stop();
  }, [audio, playAudio, stop]);

  useEffect(() => {
    if (duration && onFinished) {
      const timeout = setTimeout(onFinished, duration);
      return () => clearTimeout(timeout);
    }
    return undefined;
  }, [duration, onFinished]);

  useEffect(() => {
    if (waveDiv.current && isPlaying) {
      let loop = null;
      const anim = () => {
        setWavePos(wavePos - 1);
      };
      loop = requestAnimationFrame(anim);
      return () => {
        cancelAnimationFrame(loop);
      };
    }
    return undefined;
  }, [waveDiv, wavePos, setWavePos, isPlaying]);

  return (
    !hide && (
      <div
        ref={waveDiv}
        className="absolute-centered-h"
        style={{
          ...styles.waveWrapper,
          backgroundPositionX: wavePos,
        }}
      >
        <img src={wave} style={styles.wave} alt="" />
      </div>
    )
  );
};
AudioWave.propTypes = {
  audio: PropTypes.string.isRequired,
  onFinished: PropTypes.func,
  hide: PropTypes.bool,
};
export default AudioWave;
const styles = {
  waveWrapper: {
    bottom: vh(10),
    backgroundImage: `url(${wave})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'repeat-x',
  },
  wave: {
    width: '80vw',
    height: 'auto',
    visibility: 'hidden',
  },
};
