import React from 'react';
import { useTranslation } from 'react-i18next';

import bg from 'assets/img/bg-home.png';

const End = () => {
  const [t] = useTranslation();
  return (
    <div
      className="flex-centered col full"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'bottom right',
      }}
    >
      <h2>
        {t('Félicitations')} {t('Hemergency')}
      </h2>
    </div>
  );
};

export default End;
