import React from 'react';

const Button = (props) => {
  const { children } = props;
  return (
    <button className="absolute-centered-h" type="button" {...props}>
      {children}
      <span className="border" />
    </button>
  );
};

export default Button;
