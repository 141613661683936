import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { animated, useSpring } from 'react-spring';

import bottle from 'assets/img/bottle.png';
import pills from 'assets/img/pills.png';
import photo from 'assets/img/photo.png';
import closeSvg from 'assets/svg/close.svg';
import bgOverlay from 'assets/img/darken.png';

import { vh } from 'utils';
import PostIt from './hints/PostIt';
import Phone from './hints/Phone';
import Diary from './hints/Diary';
import Computer from './hints/Computer';

const HINTS = {
  bottle,
  pills,
  photo,
};

const HintOverlay = (props) => {
  const { hintID, closeOverlay, triggerHintFound } = props;
  const [t, i18n] = useTranslation();
  const [closing, setClosing] = useState(false);
  const fadeOutRef = useRef();

  const close = useCallback(() => {
    setClosing(true);
    fadeOutRef.current.start();
  }, [setClosing, fadeOutRef]);

  const fadeIn = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  });
  const fadeOut = useSpring({
    from: {
      opacity: 1,
    },
    to: {
      opacity: 0,
    },
    onRest: closeOverlay,
    ref: fadeOutRef,
  });

  return (
    <animated.div
      style={{
        ...(closing ? fadeOut : fadeIn),
      }}
      className="full absolute-full"
    >
      <div className="full absolute-full" style={styles.bgTransparent} />
      <div className="full absolute-full" style={styles.bgDots} />
      <div className="full absolute-full" style={styles.wrapper}>
        {hintID === 'post-it' && <PostIt />}
        {hintID === 'phone' && <Phone />}
        {hintID === 'diary' && <Diary />}
        {hintID === 'computer' && <Computer onPasswordFound={triggerHintFound} />}
        {(hintID === 'bottle' || hintID === 'pills' || hintID === 'photo') && (
          <>
            <div style={styles.hint}>
              {i18n.exists(`Hint-${hintID}`) && (
                <p style={styles.description}>{t(`Hint-${hintID}`)}</p>
              )}
            </div>
            <img style={styles.img} src={HINTS[hintID]} alt="" />
          </>
        )}
        <span style={styles.closeBT} onClick={close} role="button" tabIndex={0} />
      </div>
    </animated.div>
  );
};
HintOverlay.propTypes = {
  hintID: PropTypes.string.isRequired,
  closeOverlay: PropTypes.func.isRequired,
  triggerHintFound: PropTypes.func,
};
export default HintOverlay;
const styles = {
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 20,
  },
  bgTransparent: {
    backgroundColor: '#851a27C9',
    width: '50%',
    right: '50%',
    zIndex: 10,
  },
  bgDots: {
    backgroundImage: `url(${bgOverlay})`,
    backgroundSize: 'cover',
    width: '50%',
    left: '50%',
    zIndex: 10,
  },
  hint: {
    width: '40%',
    marginLeft: '5vw',
  },
  img: {
    width: '30%',
    height: 'auto',
    marginRight: '10vw',
  },
  closeBT: {
    position: 'absolute',
    top: vh(2),
    right: vh(2),
    backgroundImage: `url(${closeSvg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: vh(8),
    backgroundPosition: 'center center',
    width: vh(12),
    height: vh(12),
  },
};
