import React, { useCallback, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { animated, useSpring } from 'react-spring';
import { useTranslation } from 'react-i18next';

import wave from 'assets/img/voice.png';
import audio from 'assets/audio/Diagnostic-Intro.mp3';
import replay from 'assets/svg/repeat.svg';

import Logo from 'components/Logo';
import { HemergencyContext, NEXT_STEP } from 'context/hemergency-context';
import AudioWave from 'components/AudioWave';
import Button from 'components/Button';
import { vh } from 'utils';

const DiagnosticIntro = (props) => {
  const { data } = props;
  const { track, img } = data;
  const [t, i18n] = useTranslation();
  const { dispatch } = useContext(HemergencyContext);
  const [playingAudio, setPlayingAudio] = useState(false);
  const [step, setStep] = useState(0);
  const [toContinue, setToContinue] = useState(false);

  const onFadeIn = useCallback(() => {
    if (!playingAudio) {
      setPlayingAudio(true);
      setStep(step + 1);
    }
  }, [playingAudio, setPlayingAudio, step, setStep]);

  const replayIntro = useCallback(() => {
    setStep(0);
    setToContinue(false);
    setPlayingAudio(false);
    onFadeIn();
  }, [setStep, setToContinue, setPlayingAudio, onFadeIn]);

  const endStep = useCallback(() => {
    setToContinue(false);
    dispatch({
      type: NEXT_STEP,
    });
  }, [dispatch]);

  const fadeIn = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    onRest: onFadeIn,
  });

  useEffect(() => {
    if (step > 0) {
      if (i18n.exists(`${track}-${step + 1}`)) {
        const timeout = setTimeout(() => setStep(step + 1), 5000);
        return () => clearTimeout(timeout);
      }
      const timeout = setTimeout(() => setToContinue(true), 5000);
      return () => clearTimeout(timeout);
    }
    return undefined;
  }, [step, setStep, setToContinue, i18n, track]);

  useEffect(() => {
    if (toContinue) {
      setPlayingAudio(false);
    }
    return () => setPlayingAudio(false);
  }, [setPlayingAudio, toContinue]);

  return (
    <>
      <>
        <div
          style={
            toContinue
              ? {
                  filter: 'blur(4px)',
                }
              : {}
          }
        >
          <animated.img
            src={img}
            alt="Hemergency"
            className="absolute-centered"
            style={{
              ...styles.nurse,
              ...fadeIn,
            }}
          />
          <p className="absolute-centered-h" style={styles.text}>
            {step > 0 && t(`${track}-${step}`)}
          </p>
          {!toContinue && <AudioWave audio={playingAudio ? audio : null} />}
        </div>
        <Logo />
      </>
      {toContinue && (
        <div>
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <img
            className="bt absolute-centered-h"
            src={replay}
            alt={t('Rejouer')}
            style={styles.replayBT}
            onClick={replayIntro}
          />
          <Button style={styles.continueBT} onClick={endStep}>
            {t(`${track}-button`)}
          </Button>
        </div>
      )}
    </>
  );
};

DiagnosticIntro.propTypes = {
  data: PropTypes.any,
};
export default DiagnosticIntro;
const styles = {
  nurse: {
    height: vh(30),
    position: 'absolute',
    top: vh(10),
    left: '50vw',
    transform: 'translateX(-50%)',
  },
  text: {
    top: vh(45),
    width: '80%',
    fontSize: vh(4.5),
  },
  waveWrapper: {
    bottom: vh(10),
    backgroundImage: `url(${wave})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'repeat-x',
  },
  wave: {
    width: '80vw',
    height: 'auto',
    visibility: 'hidden',
  },
  replayBT: {
    width: vh(20),
    top: vh(15),
    borderRadius: '50%',
  },
  continueBT: {
    bottom: vh(10),
  },
};
