import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Player } from '@lottiefiles/react-lottie-player';

import { ROOMS_DATA } from 'Constants';
import { vh } from 'utils';

const formatTime = (secs) => {
  const minutes = (secs / 60) | 0;
  const seconds = secs - minutes * 60;
  return {
    minutes: `0${minutes}`,
    seconds: seconds < 10 ? `0${seconds}` : seconds.toString(),
  };
};

const Timeline = (props) => {
  const { paused, onHalfComplete, onComplete } = props;
  const [time, setTime] = useState(ROOMS_DATA.duration);
  const [halfPassed, setHalfPassed] = useState(false);
  const player = useRef();

  useEffect(() => {
    if (paused) {
      if (player.current) {
        player.current.pause();
      }
      return undefined;
    }
    if (player.current) {
      player.current.play();
    }
    let timeout = 0;
    if (time > 0) {
      if (!halfPassed && time === ROOMS_DATA.duration / 2 && typeof onHalfComplete === 'function') {
        setHalfPassed(true);
        onHalfComplete();
      }
      timeout = setTimeout(() => setTime(time - 1), 1000);
    } else if (typeof onComplete === 'function') {
      clearTimeout(timeout);
      onComplete();
    }
    return () => clearTimeout(timeout);
  }, [paused, time, setTime, onHalfComplete, onComplete, halfPassed, setHalfPassed]);

  return (
    <div className="absolute-centered-h" style={styles.wrapper}>
      <div
        className="absolute-centered-h"
        style={{
          bottom: 0,
        }}
      >
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {formatTime(time).minutes}:
        </span>
        <span>{formatTime(time).seconds}</span>
      </div>
      <Player autoplay ref={player} mode="normal" src="timeline.json" style={styles.player} />
    </div>
  );
};
Timeline.propTypes = {
  paused: PropTypes.bool,
  onHalfComplete: PropTypes.func,
  onComplete: PropTypes.func,
};
export default Timeline;
const styles = {
  wrapper: {
    pointerEvents: 'none',
    fontSize: vh(7),
    bottom: vh(5),
    width: '100%',
  },
  player: {
    position: 'relative',
    bottom: vh(5),
    width: '100%',
  },
};
