import React, { useCallback, useContext, useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Textfit } from 'react-textfit';

import nurse from 'assets/svg/nurse.svg';
import arrow from 'assets/svg/arrow.svg';
import cells from 'assets/img/schizocystes.png';

import { HemergencyContext, NEXT_STEP } from 'context/hemergency-context';
import { DIAGNOSTIC } from 'Constants';
import { vh, vw } from 'utils';
import AudioWave from 'components/AudioWave';

const IMAGES = {
  Schistocyte: cells,
};
const AUDIO = {
  '0-q': require('assets/audio/0-q.mp3'),
  '0-solution-0': require('assets/audio/0-solution-0.mp3'),
  '0-solution-1': require('assets/audio/0-solution-1.mp3'),

  '1-q': require('assets/audio/1-q.mp3'),
  '1-solution-0': require('assets/audio/1-solution-0.mp3'),
  '1-solution-1': require('assets/audio/1-solution-1.mp3'),
  '1-solution-2': require('assets/audio/1-solution-2.mp3'),
  '1-solution-3': require('assets/audio/1-solution-3.mp3'),

  '2-q': require('assets/audio/2-q.mp3'),
  '2-solution-0': require('assets/audio/2-solution-0.mp3'),
  '2-solution-1': require('assets/audio/2-solution-1.mp3'),
  '2-solution-2': require('assets/audio/2-solution-2.mp3'),
  '2-solution-3': require('assets/audio/2-solution-3.mp3'),

  '3-q': require('assets/audio/3-q.mp3'),
  '3-solution-0': require('assets/audio/3-solution-0.mp3'),
  '3-solution-1': require('assets/audio/3-solution-1.mp3'),
  '3-solution-2': require('assets/audio/3-solution-2.mp3'),

  '5-q': require('assets/audio/5-q.mp3'),
  '5-solution-0': require('assets/audio/5-solution-0.mp3'),
  '5-solution-1': require('assets/audio/5-solution-1.mp3'),

  '6-q': require('assets/audio/6-q.mp3'),
  '6-solution-0': require('assets/audio/6-solution-0.mp3'),
  '6-solution-1': require('assets/audio/6-solution-1.mp3'),
  '6-solution-2': require('assets/audio/6-solution-2.mp3'),
  '6-solution-3': require('assets/audio/6-solution-3.mp3'),

  '7-q': require('assets/audio/7-q.mp3'),
  '7-solution-0': require('assets/audio/7-solution-0.mp3'),
  '7-solution-1': require('assets/audio/7-solution-1.mp3'),
  '7-solution-2': require('assets/audio/7-solution-2.mp3'),
  '7-solution-3': require('assets/audio/7-solution-3.mp3'),

  'PTTA-final': require('assets/audio/PTTA-final.mp3'),
  'PTTA-final-2': require('assets/audio/PTTA-final-2.mp3'),
};

const Diagnostic = () => {
  const [t] = useTranslation('diagnostic');
  const [step, setStep] = useState(0);
  const [imageStep, setImageStep] = useState(0);
  const [showingSolution, setShowingSolution] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const { dispatch } = useContext(HemergencyContext);

  const fadeIn = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  });

  const onOptionClicked = useCallback(
    (e) => {
      const { id } = e.currentTarget.dataset;
      const option = parseInt(id, 10);

      // If multiple answers are possible
      if (DIAGNOSTIC[step].options.filter((o) => o.correct).length > 1) {
        if (selectedOptions.includes(option)) {
          setSelectedOptions(selectedOptions.filter((o) => o !== option));
        } else {
          setSelectedOptions([...selectedOptions, option]);
        }
      } else {
        setSelectedOptions([option]);
        setShowingSolution(true);
      }
    },
    [setSelectedOptions, setShowingSolution, selectedOptions, step]
  );

  const validateAnswer = useCallback(() => {
    setShowingSolution(true);
  }, [setShowingSolution]);

  const onAudioFinished = useCallback(() => {
    if (showingSolution) {
      setTimeout(() => {
        setStep(step + 1);
        setSelectedOptions([]);
        setShowingSolution(false);
      }, 500);
    } else if (!!DIAGNOSTIC[step] && !!DIAGNOSTIC[step].text) {
      setTimeout(() => setStep(step + 1), 500);
    }
  }, [step, setStep, showingSolution]);

  useEffect(() => {
    // If no more steps: The game is over
    if (DIAGNOSTIC.length <= step) {
      dispatch({
        type: NEXT_STEP,
      });
    } else if (step > 0) {
      setShowingSolution(false);
      setSelectedOptions([]);
      // If there are no options to select, add a timeout to go to the next step
      if (DIAGNOSTIC[step].image) {
        const timeout = setTimeout(() => setStep(step + 1), 8000);
        return () => clearTimeout(timeout);
      }
    }
    return undefined;
  }, [step, setStep, setSelectedOptions, setShowingSolution, dispatch]);

  const firstCorrectOptionSelected =
    !!DIAGNOSTIC[step] && !!DIAGNOSTIC[step].options
      ? DIAGNOSTIC[step].options.findIndex((o) => o.correct && selectedOptions.includes(o))
      : -1;
  return (
    <>
      {(!DIAGNOSTIC[step] || !DIAGNOSTIC[step].image) && (
        <animated.img
          src={nurse}
          alt="Hemergency"
          className="absolute-centered"
          style={{
            ...styles.nurse,
            ...fadeIn,
          }}
        />
      )}
      {selectedOptions.length > 0 && !showingSolution && (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <img src={arrow} alt="Valider" style={styles.validBT} onClick={validateAnswer} />
      )}
      {!!DIAGNOSTIC[step] && !!DIAGNOSTIC[step].options && (
        <>
          <Textfit mode="multi" className="absolute-centered-h" style={styles.text}>
            {t(`${step}-q`)}
          </Textfit>
          <div className="absolute-centered-h" style={styles.optionsWrapper}>
            {DIAGNOSTIC[step].options.map((option, i) => (
              <span
                key={`${step}-${i}`}
                data-id={i}
                onClick={!showingSolution ? onOptionClicked : null}
                className={classnames('diagnostic-option', {
                  valid: showingSolution && option.correct,
                  invalid: showingSolution && !option.correct && selectedOptions.includes(i),
                  selected: !showingSolution && selectedOptions.includes(i),
                })}
              >
                <Textfit mode="multi">
                  «&nbsp;
                  {t(`${step}-option-${i}`)}
                  &nbsp;»
                </Textfit>
              </span>
            ))}
          </div>
          <AudioWave
            audio={
              showingSolution && selectedOptions.length > 0
                ? // Take the first correct option that was selected.
                  firstCorrectOptionSelected > -1
                  ? AUDIO[
                      `${step}-solution-${
                        DIAGNOSTIC[step].options[firstCorrectOptionSelected].solution - 1
                      }`
                    ]
                  : AUDIO[
                      `${step}-solution-${
                        DIAGNOSTIC[step].options[selectedOptions[0]].solution - 1
                      }`
                    ]
                : AUDIO[`${step}-q`]
            }
            hide
            onFinished={onAudioFinished}
          />
        </>
      )}
      {!!DIAGNOSTIC[step] && !!DIAGNOSTIC[step].image && (
        <>
          <div style={styles.screen} className="absolute-centered">
            <span className="border" />
            {imageStep === 0 && (
              <>
                <table
                  style={{
                    width: '100%',
                    minHeight: '100%',
                  }}
                >
                  <tbody>
                    <tr>
                      <td style={styles.firstCell}>{t('Hémoglobine')}</td>
                      <td>8 g/dL</td>
                      <td>
                        [12 {t('à')} 16 g/dL]
                        <br />
                      </td>
                    </tr>
                    <tr>
                      <td style={styles.firstCell}>{t('CCMH')}</td>
                      <td>34 g/100mL</td>
                      <td>[30 {t('à')} 35 g/dL]</td>
                    </tr>
                    <tr>
                      <td style={styles.firstCell}>{t('VGM')}</td>
                      <td>85 fL</td>
                      <td>[80 {t('à')} 95 fL]</td>
                    </tr>
                    <tr>
                      <td style={styles.firstCell}>{t('TCMH')}</td>
                      <td>28 pg</td>
                      <td>[24 {t('à')} 30 pg]</td>
                    </tr>
                    <tr>
                      <td style={styles.firstCell}>{t('Réticulocytes')}</td>
                      <td>135000/mm3</td>
                      <td>
                        [25000 {t('à')} 100000/mm3]
                        <br />
                      </td>
                    </tr>
                    <tr>
                      <td style={styles.firstCell}>{t('Plaquettes')}</td>
                      <td>25 G/L</td>
                      <td>
                        [150 {t('à')} 500 G/L]
                        <br />
                      </td>
                    </tr>
                    <tr>
                      <td style={styles.firstCell}>{t('Leucocytes')}</td>
                      <td>12000/mm3</td>
                      <td>[4000 {t('à')} 10000/mm3]</td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}
            {imageStep === 1 && (
              <img
                style={styles.cellsImg}
                className="absolute-full"
                src={IMAGES[DIAGNOSTIC[step].image]}
                alt=""
              />
            )}
          </div>
          {imageStep === 0 && (
            <span
              style={{
                ...styles.arrow,
                ...styles.arrowRight,
              }}
              onClick={() => setImageStep(imageStep + 1)}
              role="button"
              tabIndex={0}
            />
          )}
          {imageStep > 0 && (
            <span
              style={styles.arrow}
              onClick={() => setImageStep(imageStep - 1)}
              role="button"
              tabIndex={0}
            />
          )}
          {/* <animated.img alt='' style={styles.image} src={IMAGES[DIAGNOSTIC[step].image]} /> */}
        </>
      )}
      {!!DIAGNOSTIC[step] && !!DIAGNOSTIC[step].text && (
        <>
          <Textfit mode="multi"
            className="absolute-centered-h"
            style={{
              ...styles.text,
              top: vh(40),
            }}
          >
            «&nbsp;
            {t(DIAGNOSTIC[step].text)}
            &nbsp;»
          </Textfit>
          <AudioWave key={step} audio={AUDIO[DIAGNOSTIC[step].text]} onFinished={onAudioFinished} />
        </>
      )}
    </>
  );
};

export default Diagnostic;
const styles = {
  nurse: {
    height: vh(11),
    position: 'absolute',
    top: vh(8),
    left: '50vw',
    transform: 'translateX(-50%)',
  },
  text: {
    top: vh(23),
    width: '85%',
    fontSize: vh(3.5),
    height: vh(21),
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  optionsWrapper: {
    top: vh(50),
    height: vh(40),
    width: '90vw',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr 1fr',
    columnGap: vh(4),
    rowGap: vh(4),
  },
  validBT: {
    position: 'absolute',
    height: vh(20),
    top: vh(8),
    right: 20,
    transform: 'rotate(180deg)',
  },
  image: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: 'calc(100vh - 30px)',
    width: 'auto',
  },
  screen: {
    border: 'solid 2px #C24C56',
    boxShadow: '#C24C56 0px 0px 4px 0px',
    backgroundColor: '#841F2866',
    width: '70vw',
    height: vh(65),
    borderRadius: 15,
    padding: vh(5),
    textAlign: 'justify',
    overflow: 'auto',
    top: '50%',
  },
  arrow: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: vh(5),
    backgroundImage: `url(${arrow})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: vh(8),
    backgroundPosition: 'center center',
    width: vh(12),
    height: vh(12),
  },
  arrowRight: {
    left: 'auto',
    right: vh(5),
    transform: 'translateY(-50%) rotate(180deg)',
  },
  cellsImg: {
    width: '100%',
    height: '100%',
  },
  firstCell: {
    textAlign: 'right',
    fontWeight: 'bold',
    paddingRight: vw(2),
  },
};
