import React, { useReducer } from 'react';
import DeviceOrientation, { Orientation } from 'react-screen-orientation';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { isMobileSafari } from 'react-device-detect';

import './i18n';
import './App.css';
import reducer, { initialState, HemergencyContext } from './context/hemergency-context';

import WarningOrientation from './components/WarningOrientation';
import Home from './components/steps/Home';
import Intro from './components/steps/Intro';
import Room from './components/steps/Room';
import Scan from './components/steps/Scan';
import DiagnosticIntro from './components/steps/DiagnosticIntro';
import Diagnostic from './components/steps/Diagnostic';
import End from './components/steps/End';

const steps = [Home, Intro, Scan, Room, Scan, DiagnosticIntro, Diagnostic, End];

const App = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { currentStep, customData } = state;
  const CurrentStep = steps[currentStep] || null;
  const handle = useFullScreenHandle();

  return (
    <React.StrictMode>
      <HemergencyContext.Provider
        value={{
          state,
          dispatch,
        }}
      >
        <DeviceOrientation className="App" lockOrientation="landscape">
          <Orientation orientation="landscape" alwaysRender>
            {isMobileSafari ? (
              !!CurrentStep && <CurrentStep data={customData} isFullscreen={handle.active} />
            ) : (
              <>
                <FullScreen className="test" handle={handle}>
                  {!!CurrentStep && <CurrentStep data={customData} isFullscreen={handle.active} />}
                </FullScreen>
                {!handle.active && (
                  <div
                    className="absolute-full"
                    onClick={handle.enter}
                    role="button"
                    tabIndex={0}
                  />
                )}
              </>
            )}
          </Orientation>
          <Orientation orientation="portrait" alwaysRender={false}>
            <WarningOrientation />
          </Orientation>
        </DeviceOrientation>
      </HemergencyContext.Provider>
    </React.StrictMode>
  );
};

export default App;
