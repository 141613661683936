import React from 'react';

export const HemergencyContext = React.createContext();
export const RESTART_APP = 'RESTART_APP';
export const NEXT_STEP = 'NEXT_STEP';

export const initialState = {
  currentStep: 0,
  customData: null,
};

const reducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case RESTART_APP:
      return state.currentStep === 0
        ? state
        : {
            ...initialState,
          };

    case NEXT_STEP:
      return {
        ...state,
        currentStep: state.currentStep + 1,
        customData: payload || (state.customData && state.customData.next) || null,
      };

    default:
      return state;
  }
};

export default reducer;
