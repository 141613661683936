export function vh(v) {
  return `calc(var(--vh, 1vh) * ${v})`;
}

export function vw(v) {
  return `${v}vw`;
}

export function vhCalc(v) {
  const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
  return (v * h) / 100;
}

export function vwCalc(v) {
  const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  return (v * w) / 100;
}

export function vmin(v) {
  return Math.min(vh(v), vw(v));
}

export function vmax(v) {
  return Math.max(vh(v), vw(v));
}

export function max(a, b) {
  return a > b ? a : b;
}

export function min(a, b) {
  return a < b ? a : b;
}
