import React, { useContext, useCallback, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { animated, useSpring } from 'react-spring';

import bg from 'assets/img/bg-home.png';
import logo from 'assets/svg/logo.svg';

import { HemergencyContext, NEXT_STEP } from 'context/hemergency-context';
import { vh, vhCalc, vw, vwCalc } from 'utils';

const Home = () => {
  const [t, i18n] = useTranslation();
  const { dispatch } = useContext(HemergencyContext);
  const [complete, setComplete] = useState(false);
  const animFadeOutRef = useRef();
  const animFadeOutTitleRef = useRef();

  const onClick = useCallback(() => setComplete(true), [setComplete]);
  const onLogoFadeout = useCallback(
    () =>
      dispatch({
        type: NEXT_STEP,
        payload: {
          track: 'Intro',
          img: require('assets/svg/nurse.svg'),
          audio: [
            require('assets/audio/Intro-1.mp3'),
            require('assets/audio/Intro-2.mp3'),
            require('assets/audio/Intro-3.mp3'),
            require('assets/audio/Intro-4.mp3'),
            require('assets/audio/Intro-5.mp3'),
          ],
        },
      }),
    [dispatch]
  );
  const animFadeOut = useSpring({
    from: {
      width: vh(35),
      left: vw(50),
      top: (() => vhCalc(50))(),
      transform: 'translate(-50%, -85%)',
    },
    to: {
      width: vh(15),
      left: vw(5),
      top: (() => vwCalc(5))(),
      transform: 'translate(0%, 0%)',
    },
    ref: animFadeOutRef,
    onRest: onLogoFadeout,
  });
  const animFadeOutTitle = useSpring({
    from: {
      opacity: 1,
    },
    to: {
      opacity: 0,
    },
    ref: animFadeOutTitleRef,
  });

  useEffect(() => {
    if (complete) {
      animFadeOutRef.current.start();
      animFadeOutTitleRef.current.start();
    }
  }, [complete, animFadeOutRef, animFadeOutTitleRef]);

  return (
    <>
      <animated.div
        className="flex-centered col full"
        style={{
          ...styles.bg,
          ...(complete ? animFadeOutTitle : {}),
        }}
        onClick={!complete ? onClick : null}
      >
        <h1 style={styles.title}>
          <strong>Hem</strong>
          ergency
        </h1>
        <span style={styles.tagline}>
          {`${t('Et si vous aviez')} `}
          <strong>{t('3 minutes')}</strong>
          {` ${t('pour faire le bon diagnostic')}`}
          {i18n.language === 'fr' && <span>&nbsp;</span>}?
        </span>
      </animated.div>
      <animated.img
        src={logo}
        style={{
          ...styles.logo,
          ...(complete ? animFadeOut : {}),
        }}
        alt="Hermergency"
      />
    </>
  );
};

export default Home;
const styles = {
  bg: {
    backgroundImage: `url(${bg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'bottom right',
  },
  title: {
    fontWeight: 'normal',
    fontSize: 'calc(var(--vh, 1vh) * 6)',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) translateY(calc(var(--vh, 1vh) * 10))',
  },
  logo: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -85%)',
    width: 'calc(var(--vh, 1vh) * 35)',
    height: 'auto',
    pointerEvents: 'none',
  },
  tagline: {
    fontSize: '1rem',
    position: 'absolute',
    left: '50%',
    bottom: '15%',
    width: '80%',
    transform: 'translateX(-50%)',
  },
};
