import React from 'react';
import { useTranslation } from 'react-i18next';

import phone from 'assets/img/phone.png';
import { vh } from 'utils';

const Phone = () => {
  const [t] = useTranslation();
  return (
    <>
      <div style={styles.hint}>
        <p style={styles.message}>{t('Hint-phone-message-1')}</p>
        <p
          style={{
            ...styles.message,
            ...styles.messageRight,
          }}
        >
          {t('Hint-phone-message-2')}
        </p>
        <p style={styles.message}>{t('Hint-phone-message-3')}</p>
        <p
          style={{
            ...styles.message,
            ...styles.messageRight,
          }}
        >
          {t('Hint-phone-message-4')}
        </p>
      </div>
      <img style={styles.img} src={phone} alt="" />
    </>
  );
};

export default Phone;
const styles = {
  hint: {
    width: '40vw',
    height: vh(80),
    marginLeft: '10vw',
    paddingRight: '2vw',
    textAlign: 'justify',
    overflow: 'auto',
  },
  message: {
    padding: vh(4),
    marginRight: vh(5),
    border: 'solid 2px #C24C56',
    borderRadius: 15,
    backgroundColor: '#C24C56AA',
  },
  messageRight: {
    marginLeft: vh(5),
    marginRight: 0,
    backgroundColor: '#841F28AA',
  },
  img: {
    width: '30%',
    height: 'auto',
    marginRight: '10vw',
  },
};
