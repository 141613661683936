import React from 'react';

import logo from 'assets/svg/logo.svg';
import { vh, vw } from 'utils';

const Logo = () => <img src={logo} style={styles.logo} alt="Hermergency" />;
export default Logo;
const styles = {
  logo: {
    width: vh(15),
    height: 'auto',
    userSelect: 'none',
    pointerEvents: 'none',
    position: 'fixed',
    top: vw(5),
    left: vw(5),
  },
};
