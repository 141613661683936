import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import arrow from 'assets/svg/arrow.svg';
import computer from 'assets/img/computer.png';
import cells from 'assets/img/schizocystes.png';

import { COMPUTER_PASSWORD } from 'Constants';
import { vh, vw } from 'utils';

const BLANK_DIGITS = [];
for (let i = 0; i < COMPUTER_PASSWORD.length; i++) {
  BLANK_DIGITS[i] = '*';
}

const Computer = (props) => {
  const { onPasswordFound } = props;
  const [t, i18n] = useTranslation();
  const input = useRef();
  const [step, setStep] = useState(0);
  const [typingPwd, setTypingPwd] = useState(false);
  const [passwordStatus, setPasswordStatus] = useState(0);
  const [password, setPassword] = useState('');

  const focusInput = useCallback(() => input.current.focus(), [input]);
  const onInputFocus = useCallback(() => setTypingPwd(true), [setTypingPwd]);
  const onInputBlur = useCallback(() => setTypingPwd(false), [setTypingPwd]);
  const onInputChange = useCallback(() => {
    if (input.current.value.length <= COMPUTER_PASSWORD.length) {
      setPassword(input.current.value);
    } else {
      input.current.value = input.current.value.substr(0, COMPUTER_PASSWORD.length);
    }
  }, [input, setPassword]);

  const tryValidatePassword = useCallback(() => {
    if (password.toUpperCase() === COMPUTER_PASSWORD.toUpperCase()) {
      setPasswordStatus(1);
      setTimeout(() => {
        setStep(step + 1);
      }, 2000);
    } else {
      setPasswordStatus(-1);
      setTimeout(() => {
        setPasswordStatus(0);
        setPassword('');
        input.current.value = '';
      }, 2000);
    }
  }, [step, setStep, password, setPassword, setPasswordStatus, input]);

  useEffect(() => {
    if (step === 0 && password.length === COMPUTER_PASSWORD.length) {
      tryValidatePassword();
    }
  }, [step, password, tryValidatePassword]);

  // On unmount, trigger the callback if the password was found
  useEffect(
    () => () => {
      if (passwordStatus === 1 && typeof onPasswordFound === 'function') {
        onPasswordFound();
      }
    },
    [passwordStatus, onPasswordFound]
  );

  return (
    <>
      {step === 0 && (
        <>
          <div style={styles.hint}>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <p
              style={{
                ...styles.pwd,
                ...(typingPwd ? styles.pwdTyping : {}),
                ...(passwordStatus === 1
                  ? styles.pwdValid
                  : passwordStatus === -1
                  ? styles.pwdInvalid
                  : {}),
              }}
              onClick={focusInput}
            >
              {BLANK_DIGITS.map((digit, i) => (
                <span key={i} style={styles.digit}>
                  {password.length >= i + 1 ? password[i] : digit}
                </span>
              ))}
            </p>
            <input
              ref={input}
              onFocus={onInputFocus}
              onBlur={onInputBlur}
              onChange={onInputChange}
              type="text"
              style={{
                opacity: 0,
                pointerEvents: 'none',
              }}
            />
            <p>{t("L'ordinateur est bloqué par un mot de passe")}</p>
          </div>
          <img style={styles.img} src={computer} alt="" />
        </>
      )}
      {step > 0 && (
        <>
          <div style={styles.screen} className="absolute-centered">
            <span className="border" />
            {step === 1 && (
              <>
                <p
                  style={{
                    marginTop: 0,
                  }}
                >
                  {t('Hint-computer-1')}
                </p>
                <p>
                  {t('Hint-computer-2')}
                  {i18n.language === 'fr' && <span>&nbsp;</span>}:
                </p>
                <p
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                />
                <p
                  style={{
                    marginBottom: 0,
                  }}
                >
                  {t('Hint-computer-schizocystes')}
                </p>
              </>
            )}
            {step === 2 && (
              <>
                <table
                  style={{
                    width: '100%',
                    minHeight: '100%',
                  }}
                >
                  <tbody>
                    <tr>
                      <td style={styles.firstCell}>{t('Hémoglobine')}</td>
                      <td>8 g/dL</td>
                      <td style={styles.firstCell} />
                      <td>
                        [12 {t('à')} 16 g/dL]
                        <br />
                      </td>
                    </tr>
                    <tr>
                      <td style={styles.firstCell}>{t('CCMH')}</td>
                      <td>34 g/100mL</td>
                      <td style={styles.firstCell} />
                      <td>[30 {t('à')} 35 g/dL]</td>
                    </tr>
                    <tr>
                      <td style={styles.firstCell}>{t('VGM')}</td>
                      <td>85 fL</td>
                      <td style={styles.firstCell} />
                      <td>[80 {t('à')} 95 fL]</td>
                    </tr>
                    <tr>
                      <td style={styles.firstCell}>{t('TCMH')}</td>
                      <td>28 pg</td>
                      <td style={styles.firstCell} />
                      <td>[24 {t('à')} 30 pg]</td>
                    </tr>
                    <tr>
                      <td style={styles.firstCell}>{t('Réticulocytes')}</td>
                      <td>135000/mm3</td>
                      <td style={styles.firstCell} />
                      <td>
                        [25000 {t('à')} 100000/mm3]
                        <br />
                      </td>
                    </tr>
                    <tr>
                      <td style={styles.firstCell}>{t('Plaquettes')}</td>
                      <td>25 G/L</td>
                      <td style={styles.firstCell} />
                      <td>
                        [150 {t('à')} 500 G/L]
                        <br />
                      </td>
                    </tr>
                    <tr>
                      <td style={styles.firstCell}>{t('Leucocytes')}</td>
                      <td>12000/mm3</td>
                      <td style={styles.firstCell} />
                      <td>[4000 {t('à')} 10000/mm3]</td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}
            {step === 3 && (
              <img style={styles.cellsImg} className="absolute-full" src={cells} alt="" />
            )}
          </div>
          {step > 0 && step < 3 && (
            <span
              style={{
                ...styles.arrow,
                ...styles.arrowRight,
              }}
              onClick={() => setStep(step + 1)}
              role="button"
              tabIndex={0}
            />
          )}
          {step > 1 && (
            <span
              style={styles.arrow}
              onClick={() => setStep(step - 1)}
              role="button"
              tabIndex={0}
            />
          )}
        </>
      )}
    </>
  );
};
Computer.propTypes = {
  onPasswordFound: PropTypes.func,
};
export default Computer;
const styles = {
  hint: {
    width: '55vw',
    marginLeft: '5vw',
    marginRight: '5vw',
    textAlign: 'center',
  },
  img: {
    width: '30%',
    height: 'auto',
    marginRight: '10vw',
  },
  pwd: {
    border: 'solid 2px',
    borderColor: '#C24C56',
    borderRadius: 15,
    padding: 10,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    margin: 'auto',
    width: '30vw',
    height: vh(10),
  },
  pwdTyping: {
    backgroundColor: '#C24C5688',
  },
  pwdValid: {
    borderColor: '#78AF0E',
    backgroundColor: '#78AF0E88',
  },
  pwdInvalid: {
    borderColor: '#EE0000',
    backgroundColor: '#EE000088',
  },
  digit: {
    fontSize: vh(10),
    marginBottom: vh(1),
  },
  screen: {
    border: 'solid 2px #C24C56',
    boxShadow: '#C24C56 0px 0px 4px 0px',
    backgroundColor: '#841F2866',
    width: '70vw',
    height: vh(55),
    borderRadius: 15,
    padding: vh(5),
    textAlign: 'justify',
    overflow: 'auto',
    top: '40%',
  },
  arrow: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: vh(5),
    backgroundImage: `url(${arrow})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: vh(8),
    backgroundPosition: 'center center',
    width: vh(12),
    height: vh(12),
  },
  arrowRight: {
    left: 'auto',
    right: vh(5),
    transform: 'translateY(-50%) rotate(180deg)',
  },
  cellsImg: {
    width: '100%',
    height: '100%',
  },
  firstCell: {
    textAlign: 'right',
    fontWeight: 'bold',
    paddingRight: vw(2),
  },
};
