import React, { useCallback, useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { animated, useSpring } from 'react-spring';
import { useTranslation } from 'react-i18next';
import useSound from 'use-sound';
import canAutoplay from 'can-autoplay';

import wave from 'assets/img/voice.png';
import replay from 'assets/svg/repeat.svg';

import { vh, vhCalc, vwCalc } from 'utils';
import { HemergencyContext, NEXT_STEP } from 'context/hemergency-context';
import Logo from 'components/Logo';
import Button from 'components/Button';

const Intro = (props) => {
  const { data } = props;
  const { track, audio, img } = data;
  const [intro1, intro2, intro3, intro4, intro5] = audio;
  const [t, i18n] = useTranslation();
  const { dispatch } = useContext(HemergencyContext);
  const [playingAudio, setPlayingAudio] = useState(false);
  const [canPlayAudio, setCanPlayAudio] = useState(false);
  const sound1 = useSound(intro1, {
    interrupt: true,
  });
  const sound2 = useSound(intro2, {
    interrupt: true,
  });
  const sound3 = useSound(intro3, {
    interrupt: true,
  });
  const sound4 = useSound(intro4, {
    interrupt: true,
  });
  const sound5 = useSound(intro5, {
    interrupt: true,
  });
  const [step, setStep] = useState(0);
  const waveDiv = useRef();
  const [wavePos, setWavePos] = useState(0);
  const [toContinue, setToContinue] = useState(false);
  const [fadingOut, setFadingOut] = useState(false);
  const animFadeOutRef = useRef();

  const onFadeIn = useCallback(() => {
    if (!playingAudio) {
      setPlayingAudio(true);
      setStep(step + 1);
    }
  }, [playingAudio, setPlayingAudio, step, setStep]);

  const replayIntro = useCallback(() => {
    setStep(0);
    setWavePos(0);
    setToContinue(false);
    setPlayingAudio(false);
    onFadeIn();
  }, [setStep, setWavePos, setToContinue, setPlayingAudio, onFadeIn]);

  const endStep = useCallback(() => {
    setToContinue(false);
    setFadingOut(true);
    animFadeOutRef.current.start();
  }, [setToContinue, setFadingOut]);

  const onFadeout = useCallback(
    () =>
      dispatch({
        type: NEXT_STEP,
        payload: {
          qrIndex: 0,
        },
      }),
    [dispatch]
  );

  const fadeIn = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    onRest: onFadeIn,
  });
  const nurseFadeOut = useSpring({
    from: {
      height: vh(30),
      left: '50vw',
      top: vhCalc(10),
      transform: 'translateX(-50%)',
    },
    to: {
      height: vh(15),
      left: '5vw',
      top: vwCalc(5),
      transform: 'translate(0%)',
    },
    ref: animFadeOutRef,
    onRest: onFadeout,
  });

  const startAudio = useCallback(() => {
    if (i18n.exists(`${track}-${step}`)) {
      let sound;
      switch (step) {
        case 1:
          sound = sound1;
          break;
        case 2:
          sound = sound2;
          break;
        case 3:
          sound = sound3;
          break;
        case 4:
          sound = sound4;
          break;
        case 5:
          sound = sound5;
          break;
        default:
          return undefined;
      }
      const [play, { duration }] = sound;
      play();
      const timeout = setTimeout(() => setStep(step + 1), duration);
      return () => clearTimeout(timeout);
    }
    setToContinue(true);
    return undefined;
    // eslint-disable-next-line
  }, [setStep, setToContinue, i18n, track, step]);

  useEffect(() => {
    if (step > 0 && canPlayAudio) {
      startAudio();
    }
  }, [step, startAudio, canPlayAudio]);

  useEffect(() => {
    let sound;
    switch (step) {
      case 1:
        sound = sound1;
        break;
      case 2:
        sound = sound2;
        break;
      case 3:
        sound = sound3;
        break;
      case 4:
        sound = sound4;
        break;
      case 5:
        sound = sound5;
        break;
      default:
        return undefined;
    }
    if (!sound) {
      return undefined;
    }
    const [, { isPlaying }] = sound;
    if (waveDiv.current && isPlaying) {
      let loop = null;
      const anim = () => {
        setWavePos(wavePos - 1);
      };
      loop = requestAnimationFrame(anim);
      return () => {
        cancelAnimationFrame(loop);
      };
    }
    return undefined;
  }, [waveDiv, sound1, sound2, sound3, sound4, sound5, wavePos, setWavePos, step]);

  useEffect(() => {
    let sound;
    switch (step) {
      case 1:
        sound = sound1;
        break;
      case 2:
        sound = sound2;
        break;
      case 3:
        sound = sound3;
        break;
      case 4:
        sound = sound4;
        break;
      case 5:
        sound = sound5;
        break;
      default:
        return undefined;
    }
    if (!sound) {
      return undefined;
    }
    const [, { stop }] = sound;
    if (toContinue || fadingOut) {
      stop();
    }
    return () => stop();
    // eslint-disable-next-line
  }, [step, fadingOut, toContinue]);

  useEffect(() => {
    if (canPlayAudio) {
      return undefined;
    }
    const onClick = () => {
      setCanPlayAudio(true);
      startAudio();
    };
    let canPlay = false;
    canAutoplay.audio().then(({ result, error }) => {
      if (error) {
        document.body.addEventListener('click', onClick, false);
        console.error(error);
        return undefined;
      }
      canPlay = result;
      if (result) {
        setCanPlayAudio(true);
      } else {
        document.body.addEventListener('click', onClick, false);
      }
      return undefined;
    });
    return () => {
      if (!canPlay) {
        document.body.removeEventListener('click', onClick, false);
      }
    };
  }, [canPlayAudio, setCanPlayAudio, startAudio]);

  return (
    <>
      {!fadingOut ? (
        <>
          <div
            style={
              toContinue
                ? {
                    filter: 'blur(4px)',
                    height: '100%',
                  }
                : {
                    height: '100%',
                  }
            }
          >
            <animated.img
              src={img}
              alt="Hemergency"
              className="absolute-centered"
              style={{
                ...styles.nurse,
                ...fadeIn,
              }}
            />
            <p className="absolute-centered-h" style={styles.text}>
              {step > 0 && i18n.exists(`${track}-${step}`) && t(`${track}-${step}`)}
            </p>
            <animated.div
              ref={waveDiv}
              className="absolute-centered-h"
              style={{
                ...styles.waveWrapper,
                backgroundPositionX: wavePos,
              }}
            >
              <img src={wave} style={styles.wave} alt="Repeat" />
            </animated.div>
          </div>
          <Logo />
        </>
      ) : (
        <animated.img
          src={img}
          alt="Hemergency"
          className="absolute-centered"
          style={{
            ...styles.nurse,
            ...nurseFadeOut,
          }}
        />
      )}
      {toContinue && (
        <div>
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <img
            className="bt absolute-centered-h"
            src={replay}
            alt={t('Rejouer')}
            style={styles.replayBT}
            onClick={replayIntro}
          />
          <Button style={styles.continueBT} onClick={endStep}>
            {t(`${track}-button`)}
          </Button>
        </div>
      )}
    </>
  );
};

Intro.propTypes = {
  data: PropTypes.any,
};
export default Intro;
const styles = {
  nurse: {
    height: 'calc(var(--vh, 1vh) * 30)',
    position: 'absolute',
    top: 'calc(var(--vh, 1vh) * 10)',
    left: '50vw',
    transform: 'translateX(-50%)',
  },
  text: {
    top: 'calc(var(--vh, 1vh) * 40)',
    width: '80%',
    fontSize: 'calc(var(--vh, 1vh) * 4.5)',
  },
  waveWrapper: {
    bottom: 'calc(var(--vh, 1vh) * 10)',
    backgroundImage: `url(${wave})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'repeat-x',
  },
  wave: {
    width: '80vw',
    height: 'auto',
    visibility: 'hidden',
  },
  replayBT: {
    width: 'calc(var(--vh, 1vh) * 20)',
    top: 'calc(var(--vh, 1vh) * 15)',
    borderRadius: '50%',
  },
  continueBT: {
    bottom: 'calc(var(--vh, 1vh) * 10)',
  },
};
