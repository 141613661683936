import React, { useState, useCallback, useEffect, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { animated, useSpring } from 'react-spring';
import usePortal from 'react-useportal';
import Marzipano, { Viewer } from 'marzipano';
import DeviceOrientationControlMethod from 'lib/DeviceOrientationControlMethod';

import nurse from 'assets/svg/nurse.svg';
import logo from 'assets/svg/logo.svg';
import halo from 'assets/img/halo.png';

import { vh } from 'utils';
import { HemergencyContext, NEXT_STEP } from 'context/hemergency-context';
import { ROOM_HELP_DELAY, ROOMS_DATA } from 'Constants';
import HintOverlay from 'components/HintOverlay';
import Timeline from 'components/Timeline';
import TextOverlay from 'components/TextOverlay';
import Button from 'components/Button';

const HALOS = {
  pills: new Image(),
  bottle: new Image(),
  computer: new Image(),
  diary: new Image(),
  phone: new Image(),
  photo: new Image(),
  'post-it': new Image(),
};
Object.keys(HALOS).forEach((key) => {
  HALOS[key].src = halo;
  HALOS[key].style.width = '100%';
  HALOS[key].style.height = '100%';
});

const help = document.createElement('div');
help.style.width = '50px';
help.style.height = '50px';
help.style.transform = 'translate(-50%, -50%)';
help.style.opacity = '0.75';

const Room = () => {
  const { dispatch } = useContext(HemergencyContext);
  const [t] = useTranslation();
  const { Portal } = usePortal({
    bindTo: document && document.getElementById('root'),
  });

  const [started, setStarted] = useState(false);
  const [finished, setFinished] = useState(false);
  const [viewer, setViewer] = useState(null);
  const [showingHint, setShowingHint] = useState(null);
  const [showTimeWarning, setShowTimeWarning] = useState(false);
  const [timeUp, setTimeUp] = useState(false);
  const [useGyro, setUseGyro] = useState(false);
  const [currentScene, setCurrentScene] = useState('');
  const [showEndScene, setShowEndScene] = useState('');
  const [foundHints, setFoundHints] = useState([]);
  const [showingHelp, setShowingHelp] = useState(false);

  const roomElement = useRef();
  const scenes = useRef([]);
  const hintElements = useRef([]);

  const startRoom = useCallback(() => {
    function requestPermissionForIOS() {
      window.DeviceOrientationEvent.requestPermission()
        .then((response) => {
          if (response === 'granted') {
            setUseGyro(true);
          }
          setStarted(true);
        })
        .catch((e) => {
          setStarted(true);
          console.error(e);
        });
    }

    if (window.DeviceOrientationEvent) {
      if (typeof window.DeviceOrientationEvent.requestPermission === 'function') {
        requestPermissionForIOS();
      } else {
        setUseGyro(true);
        setStarted(true);
      }
    } else {
      setStarted(true);
    }
  }, [setStarted, setUseGyro]);
  const closeOverlay = useCallback(() => setShowingHint(null), [setShowingHint]);
  const findHint = useCallback(
    (hint) => {
      if (!foundHints.includes(hint)) {
        setFoundHints([...foundHints, hint]);
        scenes.current.find((s) => s.id === currentScene).foundHints.push(hint);
      }
    },
    [foundHints, setFoundHints, scenes, currentScene]
  );
  const onHintClinked = useCallback(
    (e) => {
      setShowingHint(e.currentTarget.dataset.hint);
      if (!e.currentTarget.dataset.preventAutoFind) {
        findHint(e.currentTarget.dataset.hint);
      }
    },
    [setShowingHint, findHint]
  );
  const goToScene = useCallback(
    (id) => {
      if (currentScene !== id) {
        setFoundHints(scenes.current.find((s) => s.id === id).foundHints);
        setCurrentScene(id);
      }
    },
    [currentScene, setFoundHints, setCurrentScene, scenes]
  );
  const goToNextScene = useCallback(() => {
    setShowEndScene('');
    if (currentScene === 'bedroom') {
      goToScene('kitchen');
    } else {
      goToScene('bedroom');
    }
  }, [setShowEndScene, currentScene, goToScene]);

  const onTimeHalfUp = useCallback(() => setShowTimeWarning(true), [setShowTimeWarning]);
  const onTimeUp = useCallback(() => {
    setTimeUp(true);
    setFinished(true);
  }, [setFinished, setTimeUp]);
  const finishGame = useCallback(() => setFinished(true), [setFinished]);

  const endStep = useCallback(() => {
    viewer.destroyAllScenes();
    viewer.destroy();
    setTimeUp(false);
    setFinished(false);
    dispatch({
      type: NEXT_STEP,
      payload: {
        qrIndex: 1,
        scanOnly: true,
        next: {
          track: 'Diagnostic',
          img: require('assets/svg/nurse.svg'),
          audio: require('assets/audio/intro.mp3'),
        },
      },
    });
  }, [dispatch, viewer]);

  const fadeIn = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  });

  useEffect(() => {
    if (!roomElement.current) {
      return;
    }
    if (viewer === null) {
      const v = new Viewer(roomElement.current, {
        controls: {
          mouseViewMode: 'drag',
        },
      });
      const deviceOrientationControlMethod = new DeviceOrientationControlMethod();
      const controls = v.controls();
      controls.registerMethod('deviceOrientation', deviceOrientationControlMethod);

      const geometry = new Marzipano.CubeGeometry(ROOMS_DATA.scenes[0].levels);
      const limiter = Marzipano.RectilinearView.limit.traditional(
        1024,
        (100 * Math.PI) / 180,
        (120 * Math.PI) / 180
      );
      const view = new Marzipano.RectilinearView(null, limiter);

      ROOMS_DATA.scenes.forEach((data) => {
        const source = Marzipano.ImageUrlSource.fromString(
          `/tiles/${data.id}/{z}/{f}/{y}/{x}.jpg`,
          {
            cubeMapPreviewUrl: `/tiles/${data.id}/preview.jpg`,
          }
        );
        const s = v.createScene({
          source,
          geometry,
          view,
          pinFirstLevel: true,
        });
        // Create Hints
        data.hints.forEach((hint) => {
          const element = document.createElement('div');
          element.dataset.hint = hint.id;

          const wrapper = document.createElement('div');
          wrapper.style.position = 'relative';
          wrapper.style.width = '100%';
          wrapper.style.height = '100%';
          element.appendChild(wrapper);

          const touch = document.createElement('div');
          touch.style.width = '50px';
          touch.style.height = '50px';
          touch.style.transform = 'translate(-50%, -50%)';
          touch.style.position = 'absolute';
          wrapper.appendChild(touch);

          // Helper on env gate
          if (hint.id === 'kitchen' || hint.id === 'bedroom') {
            const img = new Image();
            img.style.width = '50px';
            img.style.transform = 'translate(-50%, -50%)';
            img.src = require('assets/svg/next-room_button.svg');
            img.style.position = 'absolute';
            img.style.pointerEvents = 'none';
            wrapper.appendChild(img);
          }

          if (hint.room) {
            element.dataset.room = hint.room;
            touch.style.width = '125px';
            touch.style.height = '250px';
            element.style.transform = 'translate(-50%, -50%)';
          }
          if (hint.preventAutoFind) {
            element.dataset.preventAutoFind = true;
          }

          element.dataset.scene = data.id;
          hintElements.current.push(element);
          s.hotspotContainer().createHotspot(element, {
            yaw: hint.yaw,
            pitch: hint.pitch,
          });
        });

        scenes.current.push({
          id: data.id,
          scene: s,
          complete: false,
          foundHints: [],
        });
      });

      // Gyroscope
      if (useGyro) {
        deviceOrientationControlMethod.getPitch((err, pitch) => {
          if (!err) {
            view.setPitch(pitch);
          }
        });
        controls.enableMethod('deviceOrientation');
      }

      roomElement.current.parentNode.parentNode.prepend(roomElement.current.parentNode);
      view.setParameters(ROOMS_DATA.scenes[0].initialViewParameters);
      setViewer(v);
      setCurrentScene(ROOMS_DATA.scenes[0].id);
    }
  }, [currentScene, setCurrentScene, started, viewer, setViewer, roomElement, foundHints, useGyro]);

  /**
   * Set touch listeners on the scenes' hints
   */
  useEffect(() => {
    if (currentScene) {
      scenes.current.find((s) => s.id === currentScene).scene.switchTo();
      hintElements.current.forEach((el) => {
        if (el.dataset.room) {
          el.onclick = () => goToScene(el.dataset.room);
        } else {
          el.onclick = onHintClinked;
        }
      });
    }
  }, [currentScene, scenes, onHintClinked, hintElements, goToScene]);

  /**
   * The App container is not touchable when moving in the 360 envirtonment
   */
  useEffect(() => {
    if (started && showingHint === null && !showTimeWarning && !finished && !showEndScene) {
      document.querySelector('.App').style.pointerEvents = 'none';
    } else {
      document.querySelector('.App').style.pointerEvents = 'initial';
    }
    return () => {
      document.querySelector('.App').style.pointerEvents = 'initial';
    };
  }, [showingHint, started, showTimeWarning, finished, showEndScene]);

  /**
   * Change scenes when all hints are found
   */
  useEffect(() => {
    if (!currentScene || showingHint !== null) {
      return;
    }
    const currentRoomHints = ROOMS_DATA.scenes
      .find((s) => s.id === currentScene)
      .hints.filter((h) => h.room === undefined);
    // If all the hints of this room are found --> this romo is complete
    if (foundHints.length >= currentRoomHints.length) {
      const thisScene = scenes.current.find((s) => s.id === currentScene);
      thisScene.complete = true;

      // If all the other rooms are complete too, the game is finished
      if (!scenes.current.some((s) => s.complete === false)) {
        finishGame();
      } else {
        // If not, go to the next room
        setShowEndScene(currentScene);
      }
    }
  }, [scenes, currentScene, foundHints, goToScene, showingHint, finishGame, viewer]);

  /**
   * Help user to find a clue every 20s interval. (Except env gate)
   */
  useEffect(() => {
    if (!currentScene) {
      return undefined;
    }

    if (showingHint && !!help.parentNode) {
      help.parentNode.removeChild(help);
    }

    const timeout = setTimeout(
      () => setShowingHelp(!showingHelp),
      showingHelp ? 2000 : ROOM_HELP_DELAY
    );
    const hints = hintElements.current.filter(
      (el) =>
        el.dataset.hint !== 'kitchen' &&
        el.dataset.hint !== 'bedroom' &&
        el.dataset.scene === currentScene &&
        !foundHints.includes(el.dataset.hint)
    );
    if (showingHelp) {
      const randomIndex = Math.floor(Math.random() * hints.length);
      const selectedElement = hints[randomIndex];

      if (selectedElement) {
        selectedElement.appendChild(help);
        while (help.childNodes.length > 0) {
          help.removeChild(help.childNodes[0]);
        }
        help.appendChild(HALOS[selectedElement.dataset.hint]);
      }
    } else if (help.parentNode) {
      help.parentNode.removeChild(help);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [currentScene, showingHelp, setShowingHelp, hintElements, foundHints, showingHint]);

  return (
    <>
      {!started && !finished && (
        <>
          <img
            src={nurse}
            alt="Hemergency"
            style={{
              ...styles.nurse,
            }}
          />
          <div className="full">
            <animated.img
              className="absolute-centered-h"
              src={logo}
              style={{
                ...styles.logo,
                ...fadeIn,
              }}
              alt="Hermergency"
            />
            <p className="absolute-centered-h" style={styles.text}>
              {t('Room-instruction-1')}
              <br />
              <br />
              {t('Room-instruction-2')}
            </p>
            <Button style={styles.bt} onClick={startRoom}>
              {t('OK')}
            </Button>
          </div>
        </>
      )}
      {started && (
        <>
          <Portal>
            <div ref={roomElement} style={styles.room} id="room" className="full" />
          </Portal>
          <Timeline
            onHalfComplete={onTimeHalfUp}
            onComplete={onTimeUp}
            paused={showingHint !== null || !!showTimeWarning || !!showEndScene || !!finished}
          />
        </>
      )}
      {showingHint !== null && (
        <HintOverlay
          hintID={showingHint}
          closeOverlay={closeOverlay}
          triggerHintFound={() => findHint(showingHint)}
        />
      )}
      {showEndScene !== '' && (
        <TextOverlay onClose={goToNextScene}>{t(`Room-finished-${currentScene}`)}</TextOverlay>
      )}
      {showTimeWarning && (
        <TextOverlay onClose={() => setShowTimeWarning(false)}>
          {t('Room-time-warning')}
        </TextOverlay>
      )}
      {finished && (
        <TextOverlay onClose={endStep}>
          <span>{t(timeUp ? 'Room-time-up' : 'Room-finished')}</span>
          <Button
            style={{
              bottom: vh(20),
              width: '30vw',
            }}
            onClick={endStep}
          >
            {t("Appeler l'urgentiste")}
          </Button>
        </TextOverlay>
      )}
    </>
  );
};

export default Room;
const styles = {
  nurse: {
    height: vh(15),
    position: 'absolute',
    top: '5vw',
    left: '5vw',
  },
  logo: {
    top: '5vw',
    width: vh(35),
    height: 'auto',
    pointerEvents: 'none',
  },
  text: {
    bottom: vh(20),
    width: '80%',
    fontSize: vh(4.5),
  },
  bt: {
    bottom: vh(10),
  },
  room: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
};
