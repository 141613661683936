import React from 'react';
import { useTranslation } from 'react-i18next';

import postit from 'assets/img/post-it.png';

const PostIt = () => {
  const [t] = useTranslation();
  return (
    <>
      <div style={styles.hint}>
        <p>
          <strong>•&nbsp;Post-it 1&nbsp;: </strong>
          {t('Hint-post-it-1')}
        </p>
        <p>
          <strong>•&nbsp;Post-it 2&nbsp;: </strong>
          {t('Hint-post-it-2')}
        </p>
        <p>
          <strong>•&nbsp;Post-it 3&nbsp;: </strong>
          {t('Hint-post-it-3')}
        </p>
      </div>
      <img style={styles.img} src={postit} alt="" />
    </>
  );
};

export default PostIt;
const styles = {
  hint: {
    width: '40%',
    marginLeft: '5vw',
    textAlign: 'left',
  },
  img: {
    width: '30%',
    height: 'auto',
    marginRight: '10vw',
  },
};
